import React from 'react'
import GoogleMapReact from 'google-map-react'

const GoogleMap = () => {
  const defaultLatLng = {
    lat: 35.70168215858742,
    lng: 139.52830946020745,
  }

  const handleApiLoaded = ({ map, maps }) => {
    new maps.Marker({
      map,
      position: defaultLatLng,
    })
  }

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAR6bnUmcF99p_WIE-BZcUJgbW6B7b7BNs' }}
        defaultCenter={defaultLatLng}
        defaultZoom={15}
        onGoogleApiLoaded={handleApiLoaded}
      />
    </div>
  );
}

export default GoogleMap
