import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import GoogleMap from '../components/googleMap'
import { H1, PageWrapper, DefinitionList, StyledGoogleMap } from '../style/common/common'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const AboutPage = ({ data, pageContext, location }) => {
  const { breadcrumb: { crumbs }, } = pageContext
  const title = "会社案内"

  return (
    <Layout
      location={location}
    >
      <SEO
        title={`${title} | ${data.site.siteMetadata.title}`}
      />
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" / "
        crumbLabel={title}
      />
      <PageWrapper>
        <H1>{title}</H1>
        <DefinitionList>
          <dt>商号</dt>
          <dd>合同会社　素粒社</dd>
          <dt>所在地</dt>
          <dd>〒184-0002 東京都小金井市梶野町1-2-36 KO-TO R-04
            <p><a href="https://ko-to.info/about/" target="_blank" rel="noopener noreferrer">KO-TO</a></p>
          </dd>
          <dt>TEL</dt>
          <dd>0422-77-4020</dd>
          <dt>FAX</dt>
          <dd>042-633-0979</dd>
          <dt>メール</dt>
          <dd>info★soryusha.co.jp（★を＠に置き換えてください）</dd>
          <dt>設立</dt>
          <dd>2020年7月</dd>
          <dt>事業内容</dt>
          <dd>出版物の制作および販売ほか</dd>
          <dt>代表社員</dt>
          <dd>北野太一</dd>
        </DefinitionList>
        <StyledGoogleMap>
          <GoogleMap />
        </StyledGoogleMap>
        {/* <Link to="/">Go back to the homepage</Link> */}
      </PageWrapper>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
